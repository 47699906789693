import React from 'react'
import './styles.scss'
import Button from './../button';

const Contact = (props) => {

    return (
        <section className="contact">
            <div className="container">
                {/* <div className="header">
                    <h2>Przygotuj ofertę leasingu szybko i wygodnie</h2>
                    <p>Twój klient zyska produkt i finansowanie w jednym miejscu</p>
                </div>
                <div className="btn-wrapper">
                    <Button title={ "KALKULATOR" }
                            class="blue" 
                            arrow={ true } 
                            setFunction={ () => window.open("https://newonline.leasingoptymalny.pl/") } />
                </div> */}
                <div className="header">
                    <h2>Zapytaj o szczegóły</h2>
                </div>
                <div className="table-wrapper">
                    <div className="row">
                        <div className="col">
                            <p>Koordynator współpracy</p>
                            <p>WROCŁAW</p>
                        </div>
                        <div className="col">
                            {/* <p>Tomasz Gutowski</p> */}
                            <p><a href="tel:+48 691 711 180">+48 691 711 180</a></p>
                        </div>
                        <div className="col">
                            <p><a href="mailto:tgutowski@grenke.pl">tgutowski@grenke.pl</a></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <p>POZNAŃ</p>
                        </div>
                        <div className="col">
                            <p><a href="tel:+48 691 711 180">+48 61 872 37 48</a></p>
                        </div>
                        <div className="col">
                            <p><a href="mailto:service.poznan@grenke.pl">service.poznan@grenke.pl</a></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <p>WARSZAWA</p>
                        </div>
                        <div className="col">
                            <p><a href="tel:+48 691 711 180">+48 22 584 50 75</a></p>
                        </div>
                        <div className="col">
                            <p><a href="mailto:service.warszawa@grenke.pl">service.warszawa@grenke.pl</a></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <p>KATOWICE</p>
                        </div>
                        <div className="col">
                            <p><a href="tel:+48 691 711 180">+48 32 508 11 30</a></p>
                        </div>
                        <div className="col">
                            <p><a href="mailto:service.katowice@grenke.pl">service.katowice@grenke.pl</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact;