import React, { useState, useEffect } from 'react'
import './styles.scss'
import Button from './../button';
import Input from './../inputs/Input';
import InputPostal from './../inputs/InputPostal';
import Checkbox from './../checkbox/Checkbox';
import { getApiLink } from './../../services/getApiLink';
import ModalMessage from './../modal-message/ModalMessage';

const Form = (props) => {
    let [ forceValidateNip, setForceValidateNip ]   = useState(false);
    let [ forceValidate, setForceValidate ]         = useState(false);
    let [ nip, setNip ] = useState("");
    let [ companyName, setCompanyName ] = useState("");
    let [ companyAddress, setCompanyAddress ] = useState("");
    let [ postalCustomer, setPostalCustomer ] = useState("");
    let [ city, setCity ] = useState("");
    let [ name, setName ] = useState("");
    let [ phone, setPhone ] = useState("");
    let [ email, setEmail ] = useState("");
    let [ website, setWebsite ] = useState("");
    let [ regulationOne, setRegulationOne ] = useState(false);
    let [ regulationTwo, setRegulationTwo ] = useState(false);
    let [ regulationThree, setRegulationThree ] = useState(false);
    let [ disableBtn, setDisableBtn ] = useState(false);
    let [ activeModal, setActiveModal ] = useState(false);
    let [ reset, setReset ] = useState(false);

    let getDataFromGUS = async (type) => {
        let validateNip = await validateNIP();
        await wait(100);
        if ( validateNip ) {
            let req = new XMLHttpRequest();
            req.open('GET', `https://newonline.leasingoptymalny.pl/API/nip2.php?id=5c3fe4b4fce445d481e92a660953&nip=${nip}`);
            req.send();

            req.addEventListener("readystatechange", async function () {
                if (this.readyState === 4) {
                    let data = JSON.parse(req.response);
                    if(data && data.dane && data.dane.ErrorCode !== "4") {
                        setCompanyName(data.dane.Nazwa);
                        setCompanyAddress(data.dane.Ulica + ' ' + data.dane.NrNieruchomosci);
                        setPostalCustomer(data.dane.KodPocztowy);
                        setCity(data.dane.Miejscowosc);
                        await setForceValidate(true);
                        await wait(100);
                        await setForceValidate(false);
                    }
                }
            });
        }
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    let validateNIP = async () => {
        await setForceValidateNip(true);
        await wait(100);

        let result;
        let elements = document.querySelectorAll('.error-item-nip');
        if( elements.length > 0 ) {
            result = false;
            await setForceValidateNip(false);
        } else {
            result = true;
        }
        return result;
    }

    const validate = async () => {
        await setForceValidate(true);
        await wait(100);
        let scroll = await scrollPage();
        if ( !scroll ) {
            await sendForm();
        }
        await setForceValidate(false);
    }

    let scrollPage = () => {
        let result;
        let elements = document.querySelectorAll('div.error-item');
        let elementsRate = document.querySelectorAll('.form .error-item');

        if( elements.length > 0  || elementsRate.length > 0) {
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: (elementsRate.length > 0 ? elementsRate[0].offsetTop - 120 : elements[0].offsetTop - 120)
              });
            result = true;
        } else {
            result = false;
        }
        return result;
        
    }

    const sendForm = () => {
        if(!disableBtn) {
            setDisableBtn(true);

            let data = {
                nip: nip,
                companyName: companyName,
                companyAddress: companyAddress,
                postalCustomer: postalCustomer,
                city: city,
                name: name,
                phone: phone,
                email: email,
                website: website,
                regulationOne: regulationOne,
                regulationTwo: regulationTwo,
                regulationThree: regulationThree,
            }

            let req = new XMLHttpRequest();
            req.open('POST', `${ getApiLink() }/sendForm.php`);
            req.setRequestHeader("Content-Type", "application/json;charset=UTF-8"); 
            req.send(JSON.stringify(data));
            
            req.addEventListener("readystatechange", async function () {
                if (this.readyState === 4) {
                    await setDisableBtn(false)
                    await setActiveModal(true);

                    await setReset(true);
                    await setForceValidate(true);
                    await wait(100);
                    await setReset(false);
                    await setForceValidate(false);

                }
            });
        }
    }

    return (
        <section className="form">
            <div className="container">
                { activeModal &&
                    <ModalMessage closeModal={ () => setActiveModal(false) } 
                                button={ props.button } 
                                buttonClass="blue" 
                                buttonTitle={ "Zamknij" }
                                buttonArrow={ true } 
                                tipHeader={ "Dziękujemy za przesłanie formularza współpracy." }
                                tipText={ "<p>Skontaktujemy się wkrótce.</p><p class='reset-margin'>Zespół GRENKE</p><a href='www.grenke.pl'>www.grenke.pl</a>" } />
                }
                <div className="header">
                    <h2>Zgłoś chęć współpracy</h2>
                    <p>Jesteś zainteresowany korzyściami dla Ciebie i Twoich Klientów dzięki współpracy GRENKE - AB?<br />Chcesz uzyskać link personalizowany do generowania ofert i wniosków leasingowych? <span style={{ color: '#39637b' }}>Wypełnij formularz poniżej</span></p>
                    <h3>Informacje o Twojej firmie</h3>
                </div>
                <div className="form-inputs">
                    <div className="input-row">
                        <div className="col">
                            <Input  name="nip"
                                id="nip"
                                required={ false }
                                setFunction={ val => setNip(val) }
                                type="text"
                                placehold={ "NIP" }
                                val={ nip }
                                size="xxl" 
                                errorClass="error-item-nip"
                                regExp={/^[0-9]{10}$/}
                                allowChars={/^[0-9]{0,10}$/}
                                minChar={ 1 } 
                                reset={ reset }
                                forceValidate={ forceValidateNip || forceValidate ? true : false } 
                                tip={ true } 
                                tipHeader="NIP"
                                tipText="Wpisz 10-cyfrowy numer NIP i pobierz automatycznie dane firmy, korzystając z przycisku poniżej. Możesz również samodzielnie uzupełnić kolejne pola."/>
                        </div>
                    </div>

                    <div className="input-row">
                        <div className="col">
                            <Button title="Pobierz dane firmy" class="white" download={ true } setFunction={ () => getDataFromGUS() } />
                        </div>
                    </div>

                    <div className="input-row">
                        <div className="col">
                            <Input  name="full-name-company"
                                id="full-name-company"
                                required={ true }
                                setFunction={ val => setCompanyName(val) }
                                type="text"
                                placehold={ "Pełna nazwa firmy" }
                                val={ companyName }
                                size="xxl" 
                                regExp={/.*/}
                                allowChars={/.*/}
                                minChar={ 1 } 
                                reset={ reset }
                                forceValidate={ forceValidate } />
                        </div>
                        <div className="col">
                            <Input  name="address-company"
                                    id="address-company"
                                    required={ true }
                                    setFunction={ val => setCompanyAddress(val) }
                                    type="text"
                                    placehold={ "Adres siedziby" }
                                    val={ companyAddress }
                                    size="xxl" 
                                    regExp={/.*/}
                                    allowChars={/.*/}
                                    minChar={ 1 } 
                                    reset={ reset }
                                    forceValidate={ forceValidate } />
                        </div>
                        <div className="col">
                            <InputPostal 
                                id="provider-postal"
                                name={"provider-postal"}
                                required={ true }
                                setFunction={ val => setPostalCustomer(val) }
                                type="text"
                                placehold={ "Kod pocztowy" }
                                val={ postalCustomer } 
                                size="xxl" 
                                regExp="\d{2}-\d{3}$" 
                                allowChars={/^[0-9]{0,5}$/} 
                                minChar={ 6 } 
                                reset={ reset }
                                forceValidate={ forceValidate } />
                        </div>
                        <div className="col">
                            <Input  name="city"
                                    id="city"
                                    required={ true }
                                    setFunction={ val => setCity(val) }
                                    type="text"
                                    placehold={ "Miejscowość" }
                                    val={ city }
                                    size="xxl" 
                                    regExp={/.*/}
                                    allowChars={/.*/}
                                    minChar={ 1 } 
                                    reset={ reset }
                                    forceValidate={ forceValidate } />
                        </div>
                    </div>

                    <div className="input-row">
                        <div className="col">
                            <Input  name="city"
                                    id="city"
                                    required={ true }
                                    setFunction={ val => setName(val) }
                                    type="text"
                                    placehold={ "Imie i Nazwisko" }
                                    val={ name }
                                    size="xxl" 
                                    regExp={/.*/}
                                    allowChars={/.*/}
                                    minChar={ 1 } 
                                    reset={ reset }
                                    forceValidate={ forceValidate } />
                        </div>
                        <div className="col">
                            <Input  name="phone"
                                    id="phone"
                                    required={ true }
                                    setFunction={ val => setPhone(val) }
                                    type="text"
                                    placehold={ "Telefon" }
                                    val={ phone }
                                    size="xxl" 
                                    regExp={/^[+0-9]{9,13}$/}
                                    allowChars={/^[+0-9]{0,13}$/}
                                    minChar={ 1 } 
                                    reset={ reset }
                                    forceValidate={ forceValidate } />
                        </div>
                        <div className="col">
                            <Input  name="email"
                                    id="email"
                                    required={ true }
                                    setFunction={ val => setEmail(val) }
                                    type="text"
                                    placehold={ "Adres e-mail do kontaktu" }
                                    val={ email }
                                    size="xxl" 
                                    regExp={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/}
                                    allowChars={/.*/}
                                    minChar={ 1 } 
                                    reset={ reset }
                                    forceValidate={ forceValidate } />
                        </div>
                        <div className="col">
                            <Input  name="website"
                                    id="website"
                                    required={ false }
                                    setFunction={ val => setWebsite(val) }
                                    type="text"
                                    placehold={ "Strona www" }
                                    val={ website }
                                    size="xxl" 
                                    regExp={/.*/}
                                    allowChars={/.*/}
                                    minChar={ 0 } 
                                    reset={ reset }
                                    forceValidate={ forceValidate } />
                        </div>
                    </div>

                    <div className="checkbox-row big-margin">
                        <div className="col">
                            <Checkbox   required={ true } 
                                        id="regulation-one"
                                        setFunction={ (val) => setRegulationOne(val) }
                                        val={ regulationOne }
                                        reset={ reset }
                                        forceValidate={ forceValidate }
                                        text="Wyrażam zgodę na otrzymywanie od GRENKE treści marketingowych oraz handlowych pocztą elektroniczną oraz telefonicznie." />
                        </div>
                    </div>
                    <div className="checkbox-row">
                        <div className="col">
                            <Checkbox   required={ regulationThree ? false : true } 
                                        id="regulation-two"
                                        setFunction={ (val) => setRegulationTwo(val) }
                                        val={ regulationTwo }
                                        radioAction={ (val) => setRegulationThree(val)}
                                        radioValue={ regulationThree }
                                        reset={ reset }
                                        forceValidate={ forceValidate }
                                        text="POSIADAM UMOWĘ WSPÓŁPRACY Z GRENKE" />
                        </div>
                    </div>
                    <div className="checkbox-row">
                        <div className="col">
                            <Checkbox   required={ regulationTwo ? false : true } 
                                        id="regulation-three"
                                        setFunction={ (val) => setRegulationThree(val) }
                                        val={ regulationThree }
                                        radioAction={ (val) => setRegulationTwo(val)}
                                        radioValue={ regulationTwo }
                                        reset={ reset }
                                        forceValidate={ forceValidate }
                                        text="NIE POSIADAM UMOWY WSPÓŁPRACY Z GRENKE" />
                        </div>
                    </div>

                    <small>Administratorem danych jest GRENKELEASING Sp. z o.o.(dalej: GRENKE). Więcej informacji o przetwarzaniu danych osobowych dostępne jest w Informacji o <a href="https://newonline.leasingoptymalny.pl/od/" target="_blank">danych osobowych</a>.</small>

                    <div className="btn-wrapper">
                        <Button title={ "WYŚLIJ TERAZ" }
                                class="blue" 
                                arrow={ true } 
                                setFunction={ () => validate() } />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Form;