import React from 'react';
import Icons from './../components/icons';
import Contact from './../components/contact';
import Form from './../components/form';
import Calculator from './../components/calculator';

const Home = ( props ) => {

  return (
    <>
        <Icons />
        <Calculator />
        <Form />
        <Contact />
    </>
  );

}

export default Home;
