export let convertPrice = ( val ) => {
    let finalValue;
    let value = val.toString();
    let price = value.indexOf('.');
    let valueMain = value.slice(0, price);
    let valueRest = value.slice(price + 1, value.length);

    switch (valueMain.length) {
        case 4:
            valueMain = valueMain.substring(0, 1) + ' ' + valueMain.substring(1, valueMain.length)
            break;
        case 5:
            valueMain = valueMain.substring(0, 2) + ' ' + valueMain.substring(2, valueMain.length)
            break;
        case 6:
            valueMain = valueMain.substring(0, 3) + ' ' + valueMain.substring(3, valueMain.length)
            break;

        case 7:
            valueMain = valueMain.substring(0, 1) + ' ' + valueMain.substring(1, 4) + ' ' + valueMain.substring(4, valueMain.length)
            break;

        case 8:
            valueMain = valueMain.substring(0, 2) + ' ' + valueMain.substring(2, 5) + ' ' + valueMain.substring(5, valueMain.length)
            break;

        default:
            break;
    }

    finalValue = valueMain + ',' + valueRest;
    return finalValue;
}

export let convertAmount = ( val ) => {
    let finalValue;
    let value = val.toString();
    let price = value.indexOf('.');
    let valueMain = value.slice(0, price);

    switch (valueMain.length) {
        case 4:
            valueMain = valueMain.substring(0, 1) + ' ' + valueMain.substring(1, valueMain.length)
            break;
        case 5:
            valueMain = valueMain.substring(0, 2) + ' ' + valueMain.substring(2, valueMain.length)
            break;
        case 6:
            valueMain = valueMain.substring(0, 3) + ' ' + valueMain.substring(3, valueMain.length)
            break;
        default:
            break;
    }

    finalValue = valueMain;
    return finalValue;
}