import React, { useState, useEffect } from "react"
import "./styles.scss";

export default props => {
  let [ value, setValue ] = useState("");
  let [ focus, setFocus ] = useState( false );
  let [ error, setError ] = useState( false );
  let [ errorText, setErrorText ] = useState();
  let [ load, setLoad ] = useState( false );

  useEffect(() => {
    if ( load ) {
         props.setFunction( value );
    }
  }, [value])

  // set value
  useEffect(() => {
    if ( props.val !== value) {
      setValue(props.val)
    }
}, [props.val])

  useEffect(() => {
    if ( !load ) {
        setLoad(true);
    }
  }, [load])
  
  // force validate again when clicked submit button
  useEffect(() => {
    if ( load ) {
        validate();
    }
  }, [props.forceValidate])

    useEffect(() => {
        if(props.reset) {
            setValue("")
            setError(false);
            setErrorText();
        }
    }, [props.reset])

  const validate = () => {

      // check if empty
      if ( props.required &&  value.length === 0) {
            setErrorText('Wymagane')
            setError(true);
            return false;

        } else {
            setError(false);
        }


        //check length 
        if ( value.length < props.minChar ) {
            setError(true);       
            setErrorText('Mało znaków');
            return false;
        } else {
            setError(false);
        }

        //check format
        if ( props.regExp ) {
            
            let regExp = new RegExp(props.regExp);
            if(regExp.test(value)) {
                setError(false);
            } else {
                setError(true);       
                setErrorText('Zły format')
            }
        }

        return true;
        
  }

  let setPostalDash = (val) => {
        //check allowChars
        if ( props.allowChars  ) {

            if (val.length > 0) {
                let regExp = new RegExp(props.allowChars);
                let tempVal = val.replace('-','')
                if(!regExp.test(tempVal)) {
                    setValue(value);
                    return false
                }
            } 
        }
          
        let tempValue = val;
        // check deleting
        let checkPrevVal = value;
        let deleting = ( checkPrevVal.length > val.length ? true : false);

        if((tempValue.match(/-/g) || []).length === 2 ) {
            let searchDash = tempValue.indexOf('-');
            tempValue = val.slice(0, searchDash);
        } 
        if( tempValue.length === 2 && !deleting) {
            setValue(tempValue + "-");
        } else {
            setValue(tempValue);
        }
    }

  return (
    <div className={ "form-item " + props.size + ' ' + ( error ? 'error-item' : '' )}>
        <label className={ ( focus || props.activeInput ? 'focus':'' ) + ' ' +
                            ( value && value.length > 0 ? 'filled' : '' ) + ' ' +  
                            ( error && !props.disabledForce ? 'error' : '' ) + ' ' + 
                            ( props.activeInput ? 'disabled':'') } >
            <input
            name={props.name}
            onChange={ e => {
                setPostalDash(e.target.value);
            }}
            onClick={ () => setFocus( true )}
            onBlur={ () => { setFocus(false); validate() }}
            required={ props.required }
            type={ props.type }
            value={ value }
            id={props.id ? props.id : ''}
            className={ props.class && props.class }
            placeholder=""
            disabled={ props.disabled ? true : null }
            />
            <div className="placeholder">
                <span className="underline"></span>
                <span className="text">{ props.placehold }</span>
            </div>
            { error && 
                <div className="error-message">
                    { errorText }
                </div>
            }
            
        </label>
    </div>
  )
}
