import React from 'react'
import Logo from '../logo';
import './styles.scss'
import phone from './../../images/phone-grey-ico.svg';

const Header = (props) => {
    return (
        <header>
            <div className="container">
                <div className="logos-col">
                    <Logo setFunction={ () => false} />
                </div>
                <div className="info-col">
                    <div className={"info margin-reset"}>
                        <img src={ phone } alt="Zadzwoń" />
                        <a href="tel: 61 872 37 00">61 / 872 37 00</a>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;