import React, { useState } from 'react'
import './styles.scss'
import arrow from './../../images/arrow.svg';
import download from './../../images/download-ico.svg';

const Button = ( props ) => {

    let keyPressed = e => {
        if (e.key === "Enter") {
          props.setFunction()
        }
    }

    return (
        <div id={props.id && props.id} className={( props.tip ? 'have-tip' : '') + " btn-wrap" }>
            <div className={"btn " + props.class  + ( props.disabled ? ' disabled' : '')}  role="button" tabindex="0" onClick={ () => props.setFunction() } onKeyPress={ (e) => keyPressed(e)}>
                { props.title }
                { props.arrow && 
                    <img className="arrow" src={ arrow } alt={ props.title } />
                }
                { props.download && 
                    <img className="upload-ico" src={ download } alt="download"/>
                }
            </div>
        </div>
    )
}

export default Button;