import React, { useState, useEffect } from "react"
import "./styles.scss";
import { convertPrice, convertAmount } from './../../services/convertPrice'
import calendar from './../../images/calendar-ico.svg'
import info from './../../images/i-ico-blue.svg'
import ModalMessage from './../modal-message/ModalMessage';

export default props => {
  let [ value, setValue ] = useState("");
  let [ focus, setFocus ] = useState( false );
  let [ focusCount, setFocusCount ] = useState( 0 );
  let [ error, setError ] = useState( false );
  let [ errorText, setErrorText ] = useState();
  let [ load, setLoad ] = useState( false );
  let [ activeModal, setActiveModal ] = useState(false);
  
  //focus count
  useEffect(() => {
    setFocusCount(++focusCount);
  }, [focus])
  // first focus 
  useEffect(() => {
    if ( focusCount === 2 && props.firstLoadValue ) {
        setValue(props.firstLoadValue)
    }
  }, [focusCount])

  useEffect(() => {
      if(props.reset) {
          setValue("")
          setError(false);
          setErrorText();
      }
  }, [props.reset])

  //checkValue 
  let checkValue = (val) => {
    if(props.name == "phone" || props.name == "user-email" || props.name == "email") {
        val = val.replace(/ /g,'');
    }
    if(props.name == "nip") {
        val = val.replace(/-/g,'');
    }
    if ( props.allowChars  ) {
        if (val.length > 0) {
            let regExp = new RegExp(props.allowChars);
            if(regExp.test(val)) {
                setValue(val);
            }
        } else {
            setValue('');
        }
    } else {
        setValue(val);
    }
  }
  //change value
  useEffect(() => {
    if ( load && props.setFunction) {
        if ( props.checkEsign) {
            props.setFunction( value );
        }
        if ( props.regExp && value.length > 0 ) {
            let regExp = new RegExp(props.regExp);
            if(regExp.test(value)) {
                props.setFunction( value );
            }
        } else {
            props.setFunction( value );
        }
        
    }
  }, [value])

  // force validate again when clicked submit button
  useEffect(() => {
    if ( load ) {
        validate();
    }
  }, [props.forceValidate])

  useEffect(() => {
    if ( load ) {
        validate();
    }
  }, [props.forceValidateItems])

  // set value
  useEffect(() => {
      if ( props.val !== value) {
        setValue(props.val)
      }
  }, [props.val])

  // set if data is loaded
  useEffect(() => {
    if ( !load ) {
        setLoad(true);
    }
  }, [load])

  useEffect(() => {
    if ( props.disabledForce ) {
        setValue('')
        props.setFunction('');
    } 
  }, [props.disabledForce])

  const validate = (valItem) => {
        let val = ( valItem ? valItem : value )
            val = val === undefined ? '' : val;
    //   if ( props.checkEsign && ( val === undefined || val.length === 0) && !props.required) {
    //       setError(false);
    //       setErrorText('');
    //     return false;
    //   }

      // check if empty

      if ( props.required &&  val.length === 0) {
            setErrorText('Wymagane')
            setError(true);
            return false;

        } else {
            setError(false);
        }

        //check length 
        if ( props.required && val.length < props.minChar ) {
            setError(true);       
            setErrorText('Mało znaków');
            return false;
        } else {
            setError(false);
        }

        //check max
        if ( props.required && props.max && val > props.max ) {
            setError(true);       
            setErrorText('Max 100 sztuk');
            return false;
        } else {
            setError(false);
        }

        //nip
        if(props.name == "nip") {
            let weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
            let nip = val.replace(/[\s-]/g, '');
                      
            if (nip.length === 10 && parseInt(nip, 10) > 0) {
                    let sum = 0;
                    for(var i = 0; i < 9; i++){
                            sum += nip[i] * weights[i];
                    }                    
                    if((sum % 11) !== Number(nip[9])) {
                        setError(true);
                        setErrorText('Wprowadzony NIP nie jest poprawny.');
                        return false;
                    }
            }
        }

        //check format
        if ( props.regExp && val.length > 0) {
            
            let regExp = new RegExp(props.regExp);
            if(regExp.test(val)) {
                if ( props.notSpecial ) {
                    if(props.type && props.type == "productName") {
                        let regExpProduct = new RegExp(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/gi );
                        let search = val.match(regExpProduct);
                        // console.log(search)
                        if ( search && search.length > 0 ) {
                            if ( search.length === val.length ) {
                                // console.log(search.length);
                                // console.log(val.length);
                                setError(true);
                                setErrorText('Zły format');
                                return false;
                            }
                        }
                    }
                   
                } else {
                    setError(false);
                }
            } else {
                setError(true);       
                setErrorText('Zły format')
            }
        }

        return true;
        
  }

  return (
    <div className={ "form-item " + props.size + ' ' + ( error ? 'error-item ' + ( props.errorClass ? props.errorClass : '' ) : '' )} onClick={ () => {
        if ( props.disabledForce && props.checkFv ) {
            props.checkFv();
        }
    }} >
        <label className={  ( props.disabledForce && !value ? 'disabled-force':'' ) + ' ' +
                            // ( props.disabledForce ? 'disabled-force':'' ) + ' ' +
                            ( focus || props.activeInput ? 'focus':'' ) + ' ' +
                            ( value && value.length > 0 ? 'filled' : '' ) + ' ' +  
                            ( error && !props.disabledForce ? 'error' : '' ) + ' ' + 
                            ( props.activeInput ? 'disabled':'') }>
            <input
            name={props.name}
            onChange={ e => {
                checkValue(e.target.value);
            }}
            onFocus={  () => setFocus( true ) }
            onClick={ () => setFocus( true )}
            onBlur={ (e) => { 
                
                setFocus(false); 
                if ( props.checkEsign && e.target.value.length === 0 ) {
                    setError(false)
                } else {
                    validate();
                }

                if ( props.setValidateSelectField ) {
                    props.setForceValidateFromName()
                }
                if ( props.formatPrice && e.target.value.length >= 1 ) {
                    let val =  e.target.value
                        val = val.replace(/\s/g, '');
                    let valWithoutSpaces = val;
                        val = val.replace(',', '.')
                        val = parseFloat(val);

                    let input = parseFloat(val).toFixed(2);
                    let regExp = new RegExp(props.regExp);
                    if(regExp.test(valWithoutSpaces)) {
                        setValue(convertPrice(input));
                        validate(valWithoutSpaces);
                    }
                }
                if ( props.formatAmount && e.target.value.length >= 1 ) {
                    let val =  e.target.value
                        val = val.replace(/\s/g, '');
                    let valWithoutSpaces = val;
                        val = val.replace(',', '.')
                        val = parseFloat(val);

                    let input = parseFloat(val).toFixed(2);
                    let regExp = new RegExp(props.regExp);
                    if(regExp.test(valWithoutSpaces)) {
                        setValue(convertAmount(input));
                        validate(valWithoutSpaces);
                    }
                }

            }}
            required={ props.required }
            type={ props.type }
            className={ props.class && props.class }
            placeholder=""
            id={props.id ? props.id : ''}
            value={ value }
            disabled={ props.disabled || props.disabledForce ? true : null }
            />
            <div className="placeholder">
                <span className="underline"></span>
                <span className="text">{ props.placehold }</span>
            </div>
            { error && !props.disabledForce && 
                <div className="error-message">
                    { errorText }
                </div>
            }
            { props.calendar &&
                <img src={ calendar } alt="Kalendarz" />
            }
           
            
        </label>
        { props.tip && 
            <img src={ info } className="tip" alt="informacja" onClick={ () => setActiveModal( true )} />
        }
        { activeModal &&
            <ModalMessage closeModal={ () => setActiveModal(false) } 
                        button={ props.button } 
                        buttonClass="blue" 
                        buttonTitle={ props.buttonTitle }
                        buttonArrow={ true } 
                        tipHeader={ props.tipHeader }
                        tipText={ props.tipText } />
        }
        { props.cloud && value.length > 0 &&
        <div className="hidden">
            { value }
        </div>
        }
    </div>
  )
}
