import React, { useState, useEffect } from "react"
import "./styles.scss"
import check from './../../images/check-ico.svg'
import checkWhite from './../../images/check-white-ico.svg'
import ModalMessage from './../modal-message/ModalMessage';
import info from './../../images/i-ico.svg'

export default props => {
    let [ error, setError ] = useState(false);
    let [ load, setLoad ] = useState(false);
    let [ activeModal, setActiveModal ] = useState(false);

    useEffect(() => {
        
        if ( !load && props.val ) {
            setLoad(true);
        }
        setLoad(true);
    }, [load])

      useEffect(() => {
        if ( props.forceValidate ) {
            if ( props.required && !props.val) {
                setError(true);
            }
            if ( props.required && props.val ) {
                setError(false)
            }
        } else {
            if ( load && props.required && !props.val) {
                setError(true);
            }
            if ( load && props.required && props.val ) {
                setError(false)
            }
        }

      }, [props.val, props.forceValidate])

      useEffect(() => {
        if(props.radioAction && !props.reset) {
            if(props.radioValue) {
                setError(false);
            }
        }
      }, [props.radioAction, props.radioValue])

      useEffect(() => {
        if(props.reset) {
            props.setFunction(false);
            setError(false);
        }
      }, [props.reset])

      if(load && props.onclickItemID) {
        let click = document.getElementById(props.onclickItemID);
        if(click) {
            click.onclick = function(e) {
                props.setShowMore(true);
            }
        }
      }

      const changeValue = () => {
        if(props.radioAction) {
            props.radioAction(props.val);
        }
        props.setFunction(!props.val)
      }

    return (
        <div className={"checkbox " + ( error ? 'error error-item' : ' ' ) + ( props.class ? props.class : '')}>
            <div className="check" onClick={ () => changeValue() } id={props.id ? props.id : ''} data-value={props.val}>
                { props.val && 
                    <>
                        { props.checkWhite &&
                            <img src={ checkWhite } alt="Zaznaczony"/>
                        }
                        { !props.checkWhite &&
                            <img src={ check } alt="Zaznaczony"/>
                        }
                   </>
                }
            </div>
            <div className="text-editor" dangerouslySetInnerHTML={ {__html: (props.required ? "*" : "") + props.text} } />
            {/* {props.showMore &&
                <p className="more-or-less" onClick={() => props.setShowMore(true)}>Zobacz pełną treść.</p>
            } */}
            { props.tip && 
                <img src={ info } className="tip" alt="informacja" onClick={ () => setActiveModal( true )} />
            }
            { activeModal &&
                <ModalMessage closeModal={ () => setActiveModal(false) } 
                            button={ props.button } 
                            buttonClass="blue" 
                            buttonTitle={ props.buttonTitle }
                            buttonArrow={ true } 
                            tipHeader={ props.tipHeader }
                            tipText={ props.tipText } />
            }
            { error && 
                <div className="error-message">
                    Wymagane
                </div>
            }
        </div>
  )
}
