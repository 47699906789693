import React from 'react'
import './styles.scss'
import Button from './../button';

const Contact = (props) => {

    return (
        <section className="calculator">
            <div className="container">
                <div className="header">
                    <h2>Przygotuj ofertę leasingu szybko i wygodnie</h2>
                    <p>Twój klient zyska produkt i finansowanie w jednym miejscu</p>
                </div>
                <div className="btn-wrapper">
                    <Button title={ "KALKULATOR" }
                            class="blue" 
                            arrow={ true } 
                            setFunction={ () => window.open("https://newonline.leasingoptymalny.pl/online/kalki.php?bus=Kalkulator") } />
                </div>
            </div>
        </section>
    )
}

export default Contact;