import React from 'react'
import './styles.scss'
import Icon1 from './../../images/icon1.png';
import Icon2 from './../../images/icon2.png';
import Icon3 from './../../images/icon3.png';
import Icon4 from './../../images/icon4.png';
import Icon5 from './../../images/icon5.png';
import Icon6 from './../../images/icon6.png';

const Icons = (props) => {
    const icons = [
        {
            img: Icon1,
            title: "Wzrost sprzedaży"
        },
        {
            img: Icon2,
            title: "Wzrost marży zysku"
        },
        {
            img: Icon3,
            title: "Szybką płatność"
        },
        {
            img: Icon4,
            title: "Minimum formalności"
        },
        {
            img: Icon5,
            title: "Profesjonalną obsługę"
        },
        {
            img: Icon6,
            title: "Wsparcie i rozwiązania"
        },
    ]
    return (
        <section className="icons">
            <div className="container">
                <div className="header">
                    <h1>Współpracujesz - zyskujesz</h1>
                </div>
                <div className="icons-wrapper">
                    {icons && icons.map((icon, index) => (
                        <div className="icon" key={index}>
                            <div className="icon-img">
                                <img src={icon.img} alt={icon.title} />
                            </div>
                            <p>{icon.title}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Icons;