import React from 'react';
import Footer from './components/footer';
import Header from './components/header';
import { Routes, Route, Link, useLocation } from "react-router-dom";
import MainBanner from './components/main-banner';
import Home from './pages/Home';

function App() {
  return (
    <div className="App">
      <div className="ghost" />
      <Header />
      <MainBanner />
      <Routes>
        <Route path="/" element={ <Home /> } />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
