import React from 'react'
import './styles.scss'

const Footer = (props) => {
    return (
        <footer>
            <div className="container">
                <a href="http://google.com" target="_blank">METRYKA STRONY</a>
                <a href="https://newonline.leasingoptymalny.pl/od/" target="_blank">OCHRONA DANYCH</a>
                <p>© <a href="http://www.grenke.pl" target="_blank">GRENKE</a> { new Date().getFullYear()  }</p>
            </div>
        </footer>
    )
}

export default Footer;