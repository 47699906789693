import React from 'react'
import './styles.scss'
import Button from '../button';
import close from './../../images/close.svg'
import ico from './../../images/i-ico-blue.svg'

const ModalMessage = ( props ) => {
   
    return (
        <div className="modal-message">
            <div className="modal">
                <div className="content">
                    <div className="icons">
                        <img src={ ico } alt="Informacja" />
                        <img src={ close } alt="Zamknij" onClick={ () => props.closeModal() } />
                    </div>
                    <div className={"text " + (props.class ? props.class : "")}>
                        <h2>{ props.tipHeader }</h2>
                        <p dangerouslySetInnerHTML={ {__html: props.tipText} } />
                        { props.button && 
                            <Button class={ props.buttonClass } title={ props.buttonTitle } arrow={ props.buttonArrow } setFunction={ () => props.setFunction() } />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalMessage;