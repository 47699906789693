import React from 'react'
import './styles.scss'
import image from './../../images/main-banner.png'

const MainBanner = () => {

    return (
        <section className="main-banner">
            <img src={ image } className="banner" alt="Grenke kalkulator"/>
            <div className="box">
                <h1>LEASING OPTYMALNY / GRENKE - AB</h1>
            </div>
        </section>
    )
}

export default MainBanner;