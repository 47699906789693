import React from 'react';
import './styles.scss';
import logo from './../../images/logo.svg';
// import logoAB from './../../images/logo-ab.svg';

const Logo = ( props ) => {
    return (
        <div className="logo">
            <img src={ logo } alt="grenke.pl" className="grenke" />
            <img src={ "https://www.ab.pl/wp-content/themes/ab-theme/assets/images/ab-logo.svg" } alt="ab.pl" className="ab" />
        </div>
    )
}

export default Logo;